<template>
  <div>
    <div class="flex">
      <div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-1/2 mx-auto">

        <h1 class="text-2xl font-bold mb-5">Add a Lender</h1>

        <form @submit.prevent="submit" class="card border border-blue-200 pt-16 pb-10 px-10 md:px-16" key="lenderForm">
          
          <template v-if="getFormError(form)">
            <div class="alert alert-red-soft mb-6">
              <span class="alert-icon">!</span>
              <span>{{ getFormError(form) }}</span>
            </div>
          </template>

          <form-group
            left-icon="business-outline"
            name="name"
            v-model="form.data.name.value"
            :form="form"
          >
            Lender Name
          </form-group>

          <div class="text-right">
            <button type="submit" class="button bg-blue-500 hover:bg-blue-600 text-white" :disabled="form.loading">
              <span v-if="form.loading">Submitting...</span>
              <span v-else>Next</span>
            </button>
          </div>

        </form>

      </div>
    </div>

    <modal className="w-full md:w-2/5 xl:w-3/10 text-center pt-24" ref="successModal">

      <img :src="assets.icons.lg.checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-xs mb-10">
        Admin registered successfully
      </div>

      <router-link :to="{name: 'lenders'}" class="btn btn-blue btn-md">
        Go to Lenders
      </router-link>
      
    </modal>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: this.$options.basicForm([
          { name: 'name', rules: 'required'},
        ])
      }
    },
    methods: {
      async submit() {
        if (!this.validateForm(this.form)) {
          return false;
        }

        this.form.error = false;
        this.form.loading = true;

        await this.sendRequest('admin.lenders.new', {
          data: this.getFormData(),
          success: () => {
            this.$refs.successModal.open();
            this.form = this.resetForm(this.form);
          },
          error: error => {
            this.form.error = error;

            this.mapFormErrors(this.form, error?.response?.data?.errors);
          }
        });

        this.form.loading = false;
      }
    }
  }
</script>